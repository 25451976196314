import { gql } from '@apollo/client'

export const GET_PERSONAL_INFO = gql`
  query get_personal_info($input: StaffQuery = {}) {
    staff: queryStaff(input: $input) {
      CC_UserID
      CCID
      FirstName
      LastName
      Phone
      CountryCode
      Email
      Role
    }
  }
`
