import './manage-channels-modal.css'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  handleHideDisconnectedChannelsNotification,
  handleHideDisconnectingChannelNotification,
  setModalContent,
  setShowFirstChannelModal,
} from '../slices/connectChannelsSlice'
import { GET_STAFF } from '../../../../graphql'
import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

// Components
import ChannelContainer from './channel-container'
import { Button, Header, Icon, ModalTitle } from '@thryvlabs/maverick'
import { HeaderContainer } from '../connect-channels-modal/ModalComponent.styles'
import {
  ManageChannelsContainer,
  ChannelsWrapper,
} from './ManageChannelsModal.styles'
import { useSelector } from 'react-redux'
import { CredentialChangedNotification, Notification } from '../../notification'
import { LoadingSpinner } from '../../loading-spinner'
import RegisterPhoneNotifications from '../phone-certification-modal/register-phone-modal/RegisterPhoneNotifications'
import RegisterBusinessErrorNotif from '../register-business-name-modal/RegisterBusinessErrorNotif'
import { useInboxNotifications } from '../../../inbox/message-content/inbox-notifications/use-inbox-notifications'
import { AnimatedNotificationContainer } from '../../../inbox/message-content/inbox-notifications/animated-notification-container/animated-notification-container'
import usePhoneChannel from '../../../../hooks/use-phone-channel'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useChannelsWithReauthStatus } from '../../../../hooks/use-channels-with-reauth-status'

const ManageHeader = ({ currNumOfChannels, onClose }) => {
  const maxChannelsAllowed = useSelector(
    (state) => state.connectChannels.maxChannelsAllowed,
  )

  // const activeChannelFailed = false //variable to show the error message for when the credentials change or a connection fails
  const dispatch = useDispatch()
  const {
    channelDisconnected,
    showChannelDisconnectedNotification,
    channelDisconnecting,
    showChannelDisconnectingNotification,
  } = useSelector((state) => state.connectChannels)
  const { user } = useAuth0()
  const userId = user.cc_uid
  const { data: singleStaffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: userId },
      },
    },
  })
  const userRole = singleStaffData?.staff[0]?.Role || user.cc_role
  const { inboxNotifications } = useInboxNotifications()
  const importedRegisterBusinessErrorNotif = inboxNotifications.find(
    (notification) => notification.name === 'importedRegisterBusinessErrorNotif',
  )
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const [phoneChannel] = usePhoneChannel()
  const { toggleTenDlc } = useFlags()

  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  const reauthChannels = useChannelsWithReauthStatus()

  const UpgradeButton = () => (
    <Button
      className="rounded-[56px] border-2 focus:outline-none flex items-center justify-center"
      variant="primary"
      onClick={() => dispatch(setModalContent(4))}
    >
      UPGRADE
    </Button>
  )

  return (
    <HeaderContainer>
      <div className="flex justify-between items-center w-full">
        <div className="flex gap-4 items-center">
          <Header fontWeight="semibold" variant="h4" className="sm: text-[18px]">
            {maxChannelsAllowed === 'Unlimited'
              ? 'My Channels'
              : `My Channels (${currNumOfChannels}/${maxChannelsAllowed})`}
          </Header>
          {userRole !== '2' && <UpgradeButton />}
        </div>
        <Button variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={onClose}
          />
        </Button>
      </div>
      {(channelDisconnected?.length > 0 && showChannelDisconnectedNotification) ||
      (channelDisconnecting !== '' && showChannelDisconnectingNotification) ? (
        <CredentialChangedNotification
          variant="error"
          iconType="solid"
          iconVariant="circleExclamation"
          className="w-[37.6rem] text-left -mb-4 -mt-1 gap-x-3 gap-y-6 sm:w-full"
          handleHideDisconnectedChannelsNotification={() =>
            dispatch(handleHideDisconnectedChannelsNotification(false))
          }
          handleHideDisconnectingChannelNotification={() =>
            dispatch(handleHideDisconnectingChannelNotification(false))
          }
          updateButton={false}
        />
      ) : null}
      {isActive && (
        <RegisterPhoneNotifications className="w-[37.6rem] text-left gap-x-3 gap-y-6 sm:w-full" />
      )}
      {importedRegisterBusinessErrorNotif && (
        <AnimatedNotificationContainer
          show={importedRegisterBusinessErrorNotif.show}
        >
          <RegisterBusinessErrorNotif className="w-[37.6rem] text-left gap-x-3 gap-y-6 sm:w-full" />
        </AnimatedNotificationContainer>
      )}
      {reauthChannels?.length > 0 && (
        <Notification
          variant="error"
          iconType="solid"
          iconVariant="circleExclamation"
          className="text-left gap-x-3 -mb-3 -mt-5"
          textToRender="To apply the recent performance update for channels, please reauthenticate your connected channel accounts. Until you complete reauthentification, you will not be able to send or receive messages on those channels."
        />
      )}
      <div className="flex w-full justify-between items-center py-2">
        <ModalTitle size="md" variant="subtitle" className="">
          Manage connected channels
        </ModalTitle>
        <Button
          className="px-1.5 mb-4"
          variant="text"
          onClick={() => dispatch(setModalContent(1))}
          level={1}
        >
          {currNumOfChannels >= maxChannelsAllowed ? '' : '+ Add New'}
        </Button>
      </div>
    </HeaderContainer>
  )
}

const ManageChannelsModal = ({
  handleClose,
  openDeleteModal,
  loading,
  clickedID,
}) => {
  const dispatch = useDispatch()
  const maxChannelsAllowed = useSelector(
    (state) => state.connectChannels.maxChannelsAllowed,
  )
  const { user } = useAuth0()
  const commandCenterId = user.cc_id
  const { data: staffData } = useQuery(GET_STAFF, {
    variables: {
      comctrid: commandCenterId,
    },
  })
  const staffMembers = staffData ? staffData.staff : []
  const { channelInfo } = useSelector((state) => state.connectChannels)

  useEffect(() => {
    dispatch(setShowFirstChannelModal(false))
  }, [])

  return (
    <div className="manage-channels-modal w-[95%] m-5 sm:m-2">
      <ManageHeader
        currNumOfChannels={channelInfo?.length}
        maxNumOfChannels={maxChannelsAllowed}
        onClose={handleClose}
        clickedID={clickedID}
      />
      <ManageChannelsContainer>
        <ChannelsWrapper>
          {channelInfo?.map((channel, idx) => {
            const channelId = channel.ChannelID
            const permittedStaff = staffMembers.filter((member) => {
              const memberIsPermitted = () => {
                return member.permittedChannels?.some(
                  (permittedChannel) => permittedChannel.ChannelID === channelId,
                )
              }

              if (memberIsPermitted()) return true
              return false
            })
            return (
              <div key={idx}>
                {idx == clickedID && loading ? (
                  <div className="flex justify-center py-3">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <ChannelContainer
                    channel={{
                      provider: channel.Provider,
                      id: channel.ChannelID,
                      iconName: channel.Provider,
                      nickname: channel.Nickname ? channel.Nickname : '',
                      email:
                        channel.Provider === 'video'
                          ? 'Video Channel'
                          : channel.AccountUserValue,
                    }}
                    disableDelete={
                      channel.syncState === '1' || channel.Provider === 'phone'
                    }
                    connectError={channel.Status == '99'}
                    reauthError={channel.Status == '98' && channel.VendorID == '1'}
                    channelToDeleteIndex={idx}
                    staffMembers={permittedStaff}
                    openDeleteModal={openDeleteModal}
                  />
                )}
              </div>
            )
          })}
        </ChannelsWrapper>
        <div className="w-full flex justify-end">
          <Button
            className="px-4 pt-5"
            variant="text"
            onClick={() => handleClose()}
            level={2}
          >
            Done
          </Button>
        </div>
      </ManageChannelsContainer>
    </div>
  )
}

export default ManageChannelsModal
