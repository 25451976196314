import { useState } from 'react'
import { LoadingSpinner } from '../../../../common/loading-spinner'
import phoneFormatter from '../../../../../utils/phoneFormatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faCirclePause } from '@fortawesome/pro-light-svg-icons'
import {
  CirclePhoneIcon,
  EmptyPhotoIcon,
} from '../../../../command-center-icon/icons/icons'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { GET_S3_DOWNLOAD_LINK } from '../../../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { Icon } from '@thryvlabs/maverick'

const VoicemailDropdownMobile = ({
  selectedCall,
  setShowInfoCardFromVoicemail,
  setDisplayInfoCard,
}) => {
  const transcript = JSON.parse(selectedCall?.body)?.transcription

  const getLink = (voicemail) => {
    try {
      if (JSON.parse(voicemail?.body).link) {
        return JSON.parse(voicemail?.body).link
      }
    } catch (error) {
      // console.error(error)
    }
    try {
      if (voicemail?.body.match(/link=(https?:\/\/[^}]+)/).length > 0) {
        return voicemail?.body
          .match(/link=(https?:\/\/[^}]+)/)[1]
          .split(', transcription=')[0]
      } else if (JSON.parse(voicemail?.body).includes('link')) {
        return JSON.parse(voicemail?.body)
          ?.match(/link=(https?:\/\/[^}]+)/)?.[1]
          .split(', transcription=')[0]
      } else {
        return 'No link available'
      }
    } catch (error) {
      return 'No link available'
    }
  }

  const voicemailLink = getLink(selectedCall)
  const voicemailUUID = voicemailLink.substring(
    voicemailLink.indexOf('files/') + 6,
    voicemailLink.length - 2,
  )

  return (
    <div className="px-[24px]">
      <div className="pt-10">
        <button onClick={() => setDisplayInfoCard(false)}>
          <Icon
            variant="x"
            type="regular"
            width={'18'}
            height={'18'}
            color="#A3A5A7"
          />
        </button>
      </div>
      <div className="flex border-b-[1px] border-barely-gray pb-[12px] pt-[10px]">
        <div className="flex min-w-md:pr-[8.5px] pl-[5px]">
          <EmptyPhotoIcon />
        </div>
        <div className={`flex flex-col grow justify-center pl-[17px]`}>
          <div
            onClick={() => {
              alert('Open New Contact Sheet')
            }}
            className={`${
              selectedCall.played ? 'font-normal' : 'font-bold'
            } flex text-[16px] hover:cursor-pointer pb-[5px]`}
          >
            {selectedCall?.contact || phoneFormatter(selectedCall?.number_from)}
          </div>
          <div className="text-thryv-steel font-normal text-[14px] pt-[4px]">
            {moment(selectedCall.ts).local().format('MMMM Do YYYY')}
            {' at ' + moment(selectedCall.ts).local().format('h:mm A')}
          </div>
        </div>
        <div className="flex flex-col text-thryv-steel">
          <span className="flex pb-[2px]">
            {moment(selectedCall.ts).local().format('l')}
          </span>
        </div>
      </div>
      <div className="flex center-items justify-center gap-[30px] py-[20px] h-[70px]">
        <div className="flex w-12 justify-center">
          <AudioPlayer url={voicemailUUID} voicemail={selectedCall} />
        </div>
        <div
          onClick={() => {
            setShowInfoCardFromVoicemail(true)
          }}
        >
          <CirclePhoneIcon
            className="flex"
            height="30px"
            width="30px"
            fill="#A3A5A7"
          />
        </div>
      </div>
      <div>
        {transcript?.length > 0 && (
          <p className="bg-[#f8f9fb] text-thryv-night rounded-[4px] p-[16px]">
            {transcript}
          </p>
        )}
      </div>
    </div>
  )
}

const AudioPlayer = ({ url, voicemail }) => {
  const [audio, setAudio] = useState()
  const [playingAudio, setPlayingAudio] = useState(false)
  const [audioLoaded, setAudioLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const { VITE_NYLAS_HOST } = import.meta.env
  const { user } = useAuth0()

  const playAudio = (audioBLOB) => {
    const audioURL = URL.createObjectURL(audioBLOB)
    const audio = new Audio(audioURL)
    setLoading(false)
    setAudio(audio)
    audio.play()
    audio.addEventListener('play', () => setPlayingAudio(true))
    audio.addEventListener('ended', () => setPlayingAudio(false))
  }

  const filename = `${user.cc_id}/${voicemail.chanpk}/${
    voicemail.threadpk?.split('#')[0]
  }/${voicemail.msgpk}.mp3`

  const { data } = useQuery(GET_S3_DOWNLOAD_LINK, {
    variables: {
      filename,
    },
  })

  const s3DownloadLink = data?.getSafeS3DownloadURL.substring(
    data?.getSafeS3DownloadURL.indexOf('=') + 1,
    data?.getSafeS3DownloadURL.length - 1,
  )

  const loadVM = async () => {
    const myHeaders = new Headers()
    setLoading(true)
    myHeaders.append('Accept', 'audio/mpeg')
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const expectedResponse = await fetch(s3DownloadLink, requestOptions)

    if (expectedResponse.ok) {
      const audioBLOB = await expectedResponse.blob()
      setAudioLoaded(true)
      playAudio(audioBLOB)
    } else {
      const vonageResponse = await fetch(
        `${VITE_NYLAS_HOST}/voicemail/${url}`,
        requestOptions,
      )
      const audioBLOB = await vonageResponse.blob()
      setAudioLoaded(true)
      playAudio(audioBLOB)
    }
  }

  return (
    <div className="flex items-center">
      {loading ? (
        <div className="pt-[10px] items-center">
          <LoadingSpinner widthAndHeight={40} />
        </div>
      ) : !audioLoaded || !playingAudio ? (
        <button onClick={() => loadVM()}>
          <FontAwesomeIcon
            style={{ color: 'blue' }}
            className="color-blue"
            size="2xl"
            icon={faPlay}
          />
        </button>
      ) : (
        <button
          onClick={() => {
            audio.pause()
            setPlayingAudio(false)
          }}
        >
          <FontAwesomeIcon
            style={{ color: 'blue' }}
            className="color-blue"
            size="2xl"
            icon={faCirclePause}
          />
        </button>
      )}
    </div>
  )
}

export default VoicemailDropdownMobile
