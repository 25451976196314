import {
  Avatar,
  Button,
  Header,
  Input,
  MaskedInput,
  ParagraphText,
} from '@thryvlabs/maverick'
import { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import CustomModal from '../../../components/common/CustomModal/Modal'
import { AddImage } from './AddImage'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PERSONAL_INFO } from '../../../graphql/queries/personal-info-queries'
import { UPDATE_PERSONAL_INFO } from '../../../graphql/mutations/personal-info-mutations'
import personalInfoFormSchema from './data/personalInfoFormSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  convertAlphaToInputValue,
  getRole,
  getLocalPhone,
} from './BusinessCardForms/my-account-helpers'
import { USER_ROLES } from '../../../utils/constants'
import { Icon } from '@thryvlabs/maverick'
import { LoadingSpinner } from '../../../components/common/loading-spinner'
import { useDispatch } from 'react-redux'
import { setUserInfo } from '../../../redux-toolkit/slices/user-info/user-info.slice'
import { AnimatedNotificationContainer } from '../../../components/inbox/message-content/inbox-notifications/animated-notification-container/animated-notification-container'
import { Notification } from '../../../components/common/notification'

const EditFormButton = ({
  show,
  isMobile,
  isAccordianOpen,
  onClick,
  onAccordionClick,
}) => {
  return (
    <div className="flex">
      {!show && (
        <button
          data-testid="edit-personal"
          type="button"
          className="flex text-[#057AFF]"
          onClick={onClick}
        >
          <Icon
            type="regular"
            variant="pen"
            width="18"
            height="18"
            color="#057AFF"
          />
          <ParagraphText
            variant="reg"
            className="ml-1 font-semibold"
            color="thryv-utility-link-info"
          >
            Edit
          </ParagraphText>
        </button>
      )}

      {isMobile && (
        <button type="button" className="w-[15px] ml-2">
          {isAccordianOpen && !show ? (
            <FontAwesomeIcon
              className="text-[#231F20]"
              icon={faCaretUp}
              size={'lg'}
              onClick={onAccordionClick}
            />
          ) : !isAccordianOpen && !show ? (
            <FontAwesomeIcon
              icon={faCaretDown}
              size={'lg'}
              onClick={onAccordionClick}
            />
          ) : null}
        </button>
      )}
    </div>
  )
}

export const PersonalInformationCard = () => {
  const [isEdit, setIsEdit] = useState(false)
  const [setShowAddAvatar] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [setImageUrl] = useState()
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const [isAccordianOpen, setIsAccordianOpen] = useState(true)
  const [maskPattern, setMaskPattern] = useState('')
  const { user } = useAuth0()
  const [showNotification, setShowNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  // const [showFullFirstName, setShowFullFirstName] = useState(false)
  // const [showFullLastName, setShowFullLastName] = useState(false)
  const countryCodeRef = useRef(null)
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const [personalInfo, setPersonalInfo] = useState({
    fname: '',
    lname: '',
    email: '',
    role: '',
    phoneNumber: '',
    countryCode: '',
  })

  const formatNumber = (phoneNumber) => {
    if (personalInfo.countryCode === 'NZ') {
      phoneNumber = phoneNumber.replace(/\D/g, '')

      if (phoneNumber.length === 10) {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      } else if (phoneNumber.length === 9) {
        return phoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, '($1) $2-$3')
      } else {
        return phoneNumber
      }
    } else {
      return phoneNumber
    }
  }

  const dispatch = useDispatch()
  const {
    handleSubmit,
    getValues,
    reset,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: personalInfo,
    resolver: yupResolver(personalInfoFormSchema(countryISO)),
  })

  const setMask = (countryCode) => {
    switch (countryCode) {
      case 'US':
        return setMaskPattern('(###) ###-####')
      case 'CA':
        return setMaskPattern('(###) ###-####')
      case 'AU':
        if (
          getValues('phoneNumber')[1] === '4' ||
          personalInfo.phoneNumber[1] === '4' ||
          getValues('phoneNumber')[1] === '5' ||
          personalInfo.phoneNumber[1] === '5'
          // checks if mobile numbber and formats accordingly
        ) {
          return setMaskPattern('#### ### ###')
        } else {
          return setMaskPattern('(##) #### ####')
        }

      // case 'NZ':
      //   return setMaskPattern('(##) ###-####')
    }
    return '(###) ###-####'
  }

  useEffect(() => {
    resetValues()
  }, [personalInfo])

  useEffect(() => {
    if (!isMobile) {
      setIsAccordianOpen(true)
    }
  }, [isMobile])

  const resetValues = () => {
    reset({
      fname: personalInfo.fname,
      lname: personalInfo.lname,
      email: personalInfo.email,
      role: personalInfo.role,
      phoneNumber: formatNumber(personalInfo.phoneNumber),
      countryCode: personalInfo.countryCode,
    })
  }

  const { isLoading } = useQuery(GET_PERSONAL_INFO, {
    input: {
      CC_UserID: { eq: user.cc_uid },
    },
    onCompleted: (data) => {
      const { FirstName, LastName, Phone, Email, Role, CountryCode } = data.staff[0] //Country code comes in as Alpha2Code
      const roleName = getRole(Role)
      const validCountryCode = CountryCode || countryISO
      setMask(CountryCode)
      const localPhone = getLocalPhone(CountryCode, Phone)
      // console.log('here data phone oncompleted QUERY:', Phone)
      countryCodeRef.current = validCountryCode
      setPersonalInfo({
        fname: FirstName || '',
        lname: LastName || '',
        phoneNumber: formatNumber(localPhone),
        countryCode: convertAlphaToInputValue(validCountryCode) || '',
        email: Email || '',
        role: roleName || '',
      })
      dispatch(setUserInfo({ fname: FirstName, lname: LastName, email: Email }))
    },
    onError: () => {},
  })

  const toggleEdit = () => {
    setIsEdit(true)
    setIsAccordianOpen(true)
  }

  const closeModal = () => {
    setIsOpen(!isOpen)
  }

  const toggleAccordian = function () {
    setIsAccordianOpen(!isAccordianOpen)
  }

  // const toggleShowFullFirstName = () => {
  //   setShowFullFirstName(!showFullFirstName)
  // }

  // const toggleShowFullLastName = () => {
  //   setShowFullLastName(!showFullLastName)
  // }

  // const formatPhoneNumber = (phoneNumber) => {
  //   // Remove all non-digit characters from the phone number
  //   const digits = phoneNumber.replace(/\D/g, '')

  //   // Check if the phone number has 10 digits
  //   if (digits.length === 10) {
  //     // Extract the area code, prefix, and line number
  //     const areaCode = digits.slice(0, 3)
  //     const prefix = digits.slice(3, 6)
  //     const lineNumber = digits.slice(6)

  //     // Format the phone number as (XXX)-XXX-XXXX
  //     return `(${areaCode}) ${prefix}-${lineNumber}`
  //   }

  //   // Return the original phone number if it doesn't have 10 digits
  //   return phoneNumber
  // }

  const [updatePersonalInfoInDB, { loading: infoSubmissionLoading }] = useMutation(
    UPDATE_PERSONAL_INFO,
    {
      onCompleted: () => {
        setNotificationVariant('success')
        setNotificationMessage('Personal Information Updated Successfully!')
        setShowNotification(true)
        setTimeout(() => {
          setShowNotification(false)
        }, 2000)
        if (isMobile) {
          setTimeout(() => {
            setIsAccordianOpen(false)
          }, 2000)
        }
      },
      onError: () => {
        setNotificationVariant('error')
        setNotificationMessage('Error Updating the Personal Information')
        setShowNotification(true)
        setTimeout(() => {
          setShowNotification(false)
        }, 2000)
        if (isMobile) {
          setTimeout(() => {
            setIsAccordianOpen(false)
          }, 2000)
        }
      },
      refetchQueries: [GET_PERSONAL_INFO],
    },
  )

  const updatePersonalInformation = async () => {
    const newPersonalInfo = {
      fname: getValues('fname') || '',
      lname: getValues('lname') || '',
      role: getValues('role') || '',
      email: personalInfo.email || '',
      phoneNumber: formatNumber(getValues('phoneNumber')) || '',
      countryCode: personalInfo.countryCode || '',
    }

    const newPersonalInfoForMutation = {
      CC_UserID: user.cc_uid,
      LocationID: user.businessId,
      FirstName: newPersonalInfo.fname,
      LastName: newPersonalInfo.lname,
      Phone: newPersonalInfo.phoneNumber.replace(/\D/g, ''),
      Role: USER_ROLES[getValues('role').toLowerCase()],
    }
    dispatch(
      setUserInfo({
        fname: newPersonalInfo.fname,
        lname: newPersonalInfo.lname,
        email: newPersonalInfo.email,
      }),
    )
    setPersonalInfo(newPersonalInfo)
    await updatePersonalInfoInDB({ variables: newPersonalInfoForMutation })

    setIsEdit(false)
  }

  return (
    !isLoading && (
      <>
        <div className="w-[95%] relative bg-[white] rounded border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] p-6 sm:m-auto ">
          <div className="flex items-center justify-between w-full mb-6 max-h-[26px]">
            <Header
              fontWeight="semibold"
              variant="h6"
              className="mt-5 mb-5 leading-[26px]"
            >
              Personal Information
            </Header>

            <EditFormButton
              show={isEdit}
              isMobile={isMobile}
              isAccordianOpen={isAccordianOpen}
              onClick={toggleEdit}
              onAccordionClick={toggleAccordian}
            />
          </div>

          {isAccordianOpen && (
            <form onSubmit={handleSubmit(updatePersonalInformation)}>
              {/* <div className="grid grid-cols-8 gap-3"> */}

              <div className="flex sm:ml-0 ">
                <Avatar
                  onMouseOver={() => {
                    if (isEdit) {
                      setShowAddAvatar(true)
                    }
                  }}
                  className={'min-w-[45px] sm:ml-[2%] sm:hidden pr-1]'}
                  variant="name"
                  size="default"
                  name={{
                    firstName: personalInfo.fname,
                    lastName: personalInfo.lname,
                  }}
                />
                <div className="sm:col-span-8 col-span-6 gap-[1.5rem] grid grid-cols-8 h-[75px] sm:h-fit ml-[15px] sm:ml-0">
                  {/* comment out ability to update image for now */}
                  {/* <div className="sm:col-span-8 col-span-1">
                  {!imageUrl && (
                    <div>
                      {!showAddAvatar ? (
                        <Avatar
                          onMouseOver={() => {
                            if (isEdit) {
                              setShowAddAvatar(true)
                            }
                          }}
                          className={'sm:ml-[2%]'}
                          variant="name"
                          size="default"
                          name={{
                            firstName: user.name.split(' ')[0],
                            lastName: user.name.split(' ')[1],
                          }}
                        />
                      ) : (
                        <Avatar
                          onMouseOut={() => setShowAddAvatar(false)}
                          className={'sm:ml-[2%] ml-[15%] bg-[#606377]'}
                          variant="icon"
                          size="default"
                        >
                          <FontAwesomeIcon
                            onMouseOut={() => setShowAddAvatar(false)}
                            onClick={() => setIsOpen(true)}
                            className="text-[2rem] text-[#ffffff]"
                            icon={faCamera}
                            size={'lg'}
                          />
                        </Avatar>
                      )}
                    </div>
                  )}

                  {imageUrl && (
                    <div>
                      {!showAddAvatar ? (
                        <Avatar
                          onMouseOver={() => {
                            if (isEdit) {
                              setShowAddAvatar(true)
                            }
                          }}
                          className={'sm:ml-[2%] ml-[15%] overflow-hidden'}
                          variant="icon"
                          size="default"
                        >
                          <img src={imageUrl} />
                        </Avatar>
                      ) : (
                        <Avatar
                          onMouseOut={() => setShowAddAvatar(false)}
                          className={'sm:ml-[2%] ml-[15%] bg-[#606377]'}
                          variant="icon"
                          size="default"
                        >
                          <FontAwesomeIcon
                            onMouseOut={() => setShowAddAvatar(false)}
                            onClick={() => setIsOpen(true)}
                            className="text-[2rem] text-[#ffffff]"
                            icon={faCamera}
                            size={'lg'}
                          />
                        </Avatar>
                      )}
                    </div>
                  )}
                </div> */}

                  {/* <div className="sm:col-span-8 col-span-1">
                  <div className="relative sm:mt-5 w-full mt-[14px] ml-[30px] sm:ml-0">
                    <Avatar
                      onMouseOver={() => {
                        if (isEdit) {
                          setShowAddAvatar(true)
                        }
                      }}
                      className={'sm:ml-[2%] sm:hidden pr-1]'}
                      variant="name"
                      size="default"
                      name={{
                        firstName: personalInfo.fname,
                        lastName: personalInfo.lname,
                      }}
                    />
                  </div>
                </div> */}
                  <div className="sm:col-span-8 col-span-3 ">
                    <div className="relative sm:mt-5 w-full mt-[14px]">
                      <Controller
                        control={control}
                        rules={{ required: 'First Name is required' }}
                        name="fname"
                        render={({ field }) => (
                          <Input
                            disabled={!isEdit || infoSubmissionLoading}
                            className="w-[100%] bg-white sm:w-[296px] disabled:text-thryv-black-500"
                            type="text"
                            placeholder="First Name"
                            {...field}
                            name="fname"
                            withLabel
                            labelType="static"
                            variant="default"
                            register={register}
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-8 col-span-3">
                    <div className="sm:mt-5 w-full mt-[14px]">
                      <Controller
                        control={control}
                        rules={{ required: 'Last Name is required' }}
                        name="lname"
                        render={({ field }) => (
                          <Input
                            disabled={!isEdit || infoSubmissionLoading}
                            className="w-[100%] bg-white sm:w-[296px]"
                            type="text"
                            placeholder="Last Name"
                            {...field}
                            name="lname"
                            withLabel
                            labelType="static"
                            variant="default"
                            register={register}
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-8 col-span-2">
                    <div className="sm:mt-5 w-full mt-[14px] relative">
                      <Controller
                        control={control}
                        name="role"
                        render={({ field }) => (
                          <Input
                            disabled
                            labelType="static"
                            className="w-[100%] bg-white sm:w-[296px]"
                            type="text"
                            placeholder="Role"
                            {...field}
                            name="role"
                            withLabel
                            variant="default"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-col gap-[1.5rem] mt-8 sm:mt-[27px] h-[75px] sm:h-fit sm:ml-0 ">
                <div className="w-[400px]">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <Input
                        disabled
                        className="w-full bg-white sm:w-[296px]"
                        type="email"
                        placeholder="Email"
                        name="email"
                        {...field}
                        withLabel
                        labelType="static"
                        variant="default"
                      />
                    )}
                  />
                </div>

                <div className="relative ">
                  <Input
                    className="bg-white sm:w-[296px]"
                    type="tel"
                    customPlaceholder={personalInfo.countryCode}
                    name="countryCode"
                    withLabel
                    labelType="static"
                    variant="default"
                    disabled={true}
                  />
                  <label
                    htmlFor="countryCode"
                    className='pointer-events-none absolute left-0 -top-[16px] capitalize 
                font-open-sans text-label-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text 
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                  >
                    Country Code
                  </label>
                </div>

                {personalInfo.countryCode === 'NZ' ? (
                  <div className="sm:col-span-8 col-span-1 mt-[7px]">
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field: { ...rest } }) => (
                        <Input
                          disabled={!isEdit}
                          className="w-[100%] bg-white"
                          type="tel"
                          customPlaceholder="Phone Number"
                          {...rest}
                          withLabel
                          labelType="static"
                          variant="default"
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div className="md:w-full">
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field: { ...rest } }) => (
                        <MaskedInput
                          disabled={!isEdit || infoSubmissionLoading}
                          className="w-full bg-white sm:w-[296px]"
                          type="tel"
                          mask={maskPattern}
                          placeholder="Phone Number"
                          name="phoneNumber"
                          withLabel
                          labelType="static"
                          variant="default"
                          errors={errors}
                          {...rest}
                        />
                      )}
                    />
                  </div>
                )}
              </div>

              <div className="flex w-full justify-end items-center gap-4 sm:w-[286px] sm:justify-center sm:mt-[50px] sm:mb-[20px] h-[34px]">
                {isEdit && !infoSubmissionLoading && (
                  <>
                    <Button
                      onClick={() => {
                        if (isMobile) {
                          setIsAccordianOpen(false)
                        }
                        setIsEdit(false)
                        resetValues()
                      }}
                      variant="text"
                      level={2}
                      textVariant="light"
                    >
                      Cancel
                    </Button>

                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  </>
                )}

                {infoSubmissionLoading && <LoadingSpinner />}
              </div>
            </form>
          )}
          <CustomModal
            isModalForm={true}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleClose={closeModal}
            width={'60%'}
          >
            <AddImage setImageUrl={setImageUrl} handleClose={closeModal} />
          </CustomModal>

          <div className="absolute sm:left-2 bottom-3 w-[97%]">
            <AnimatedNotificationContainer show={showNotification}>
              <Notification
                className="relative bg-blue-500 p-2 rounded-lg"
                iconType={'solid'}
                variant={notificationVariant}
                textToRender={notificationMessage}
                close={() => {
                  setShowNotification(false)
                }}
              />
            </AnimatedNotificationContainer>
          </div>
        </div>
      </>
    )
  )
}
