import { useEffect, useState, useRef } from 'react'
import Pill from '../Pill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatedDiv } from './MenuItem.styles'
import CustomStyledTooltip from '../CustomStyledTooltip'
import leftNavData from '../../../leftNavData'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_PREFERENCES } from '../../../graphql/mutations'
import { CommandCenterIcon } from '../../command-center-icon'
import linkIcon from '../../../assets/link-icon.png'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setMobileNavOpen } from '../slices/left-nav-slice'
import { setNavigationPreferences } from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { useAuth0 } from '@auth0/auth0-react'
import { setTabSelected } from '../../calls/slices/callSlice'
import { useTeamchatNotifications } from '../../inbox/hooks/useTeamchatNotifications'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@thryvlabs/maverick'

const MenuItem = ({
  options,
  collapsed,
  notifications,
  title,
  draggableIdState,
  id,
  destination,
  source,
  allowAnimate,
  checkedItems,
  outOfRangeNum,
  openEditMenuId,
  isMobile,
  isLoading,
}) =>
  // ref,
  {
    const [parentCoords, setParentCoords] = useState({ x: 0, y: 0 })
    const [isHovering, setIsHovering] = useState(false)
    const container = useRef(null)

    const CCIcon = () => {
      return (
        <CommandCenterIcon
          variant={
            options?.center === 'Custom Links'
              ? 'hyperlink'
              : leftNavData?.[options?.title]?.icon
          }
          type="regular"
          width={collapsed ? '20px' : null}
          height={collapsed ? '20px' : null}
        />
      )
    }

    const dispatch = useDispatch()
    const letAnimate = draggableIdState === id
    const { navigationPreferences } = useSelector((state) => state.userPreferences)
    const { notificationPreferences } = useSelector((state) => state.userPreferences)
    const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES)
    const { user } = useAuth0()

    useTeamchatNotifications(options?.title === 'TeamChat')

    function calculateSlideDown() {
      if (letAnimate && isLoading) {
        if (outOfRangeNum > 0) {
          let totalLengthDiff = checkedItems.length - source.index - 1
          return '-' + (totalLengthDiff * 28).toString() + 'px'
        }
        let totalLengthDiff = destination.index - source.index
        return '-' + (totalLengthDiff * 28).toString() + 'px'
      }
    }

    function calculateSlideUp() {
      if (letAnimate && isLoading) {
        let totalLengthDiff = source.index - destination.index
        return (totalLengthDiff * 28).toString() + 'px'
      }
    }

    let animationCalculation
    if (destination && source) {
      if (letAnimate && openEditMenuId > -1 && allowAnimate && isLoading) {
        destination.index > source.index
          ? (animationCalculation = calculateSlideDown())
          : (animationCalculation = calculateSlideUp())
      }
    }

    const handleRemoveLink = () => {
      const updatedState = {
        ...navigationPreferences,
        data: [...navigationPreferences.data].map((group) => {
          return {
            id: group.id,
            items: group.items.filter((item) => {
              if (item.id !== id) {
                return item
              }
            }),
            order: group.order.filter((item) => {
              if (item !== id) {
                return item
              }
            }),
            title: group.title,
          }
        }),
      }
      dispatch(setNavigationPreferences(updatedState))
      const updatedConfig = {
        navigationCollapsed: collapsed,
        notificationPreferences,
        navigationPreferences: updatedState,
      }
      updateUserPreferences({
        variables: {
          userId: user.email,
          preferences: JSON.stringify({
            time_stamp: Date.now(),
            data: updatedConfig,
          }),
        },
      })
    }

    const handleMouseEnter = (e) => {
      if (collapsed) {
        setIsHovering(container.current.contains(e.target))
        if (parentCoords.x === 0) {
          const el = document.getElementById(
            `menu-item-${options?.title.replace(/\s/g, '')}`,
          )
          const { x, y } = el.getBoundingClientRect()
          setParentCoords({ x, y })
        }
      }
    }

    const handleHover = ({ target }) => {
      if (container.current && container.current.contains(target)) {
        return
      }
      setParentCoords({ x: 0, y: 0 })
      return setIsHovering(false)
    }

    useEffect(() => {
      if (collapsed) {
        document.addEventListener('mouseover', handleHover)

        return () => {
          document.removeEventListener('mouseover', handleHover)
        }
      }
    }, [collapsed])

    const handleMenuItemClick = () => {
      if (isMobile) {
        dispatch(setMobileNavOpen(false))
      }
      if (options?.title === 'Voicemail') {
        dispatch(setTabSelected('voicemail'))
      }
      if (options?.title === 'Calls') {
        dispatch(setTabSelected('recent'))
      }
    }

    return (
      options?.title !== 'Landing Pages' && (
        <div
          ref={container}
          onMouseEnter={handleMouseEnter}
          data-testid={`menu-item-${options?.title.replace(/\s/g, '')}`}
          id={`menu-item-${options?.title.replace(/\s/g, '')}`}
          className="flex group"
        >
          <NavLink
            style={{ textDecoration: 'none' }}
            onClick={handleMenuItemClick}
            to={
              title === 'Custom Links'
                ? options?.link
                : leftNavData?.[options?.title]?.link
            }
            target={
              title === 'Custom Links' ||
              leftNavData?.[options?.title]?.openInNewTab === true
                ? '_blank'
                : ''
            }
            end
          >
            {({ isActive }) => (
              <AnimatedDiv
                animationCalculation={animationCalculation}
                letAnimate={letAnimate}
                key={options.id}
                style={
                  isActive ||
                  (window.location.pathname === '/' && options?.title === 'Inbox')
                    ? {
                        backgroundColor: `${
                          leftNavData?.[options?.title]?.activeColor
                        }`,
                      }
                    : {}
                }
                className={`flex group relative ${letAnimate ? 'z-10' : ''}
         ${
           collapsed ? 'w-[36px] py-[6px]' : 'w-[231px] md:w-[275px] py-[6px]'
         } pl-[8px] bg-white gap-[8px] rounded-[4px] hover:cursor-pointer hover:bg-[#ECEEF1] items-center text-thryv-steel hover:text-dark-gray`}
              >
                {collapsed ? (
                  <>
                    <div
                      id={`menu-item-${options?.title.replace(/\s/g, '')}`}
                      data-testid={`menu-item-${options?.title.replace(/\s/g, '')}`}
                    >
                      {title !== 'Custom Links' && <CCIcon />}
                      {title === 'Custom Links' && (
                        <img
                          className={`${
                            collapsed
                              ? 'h-[22px] w-[23px] pt-[2px]'
                              : 'pl-0 h-[16px] w-[17px]'
                          }`}
                          src={options?.icon}
                          onError={(e) => {
                            e.target.onError = null
                            e.target.src = linkIcon
                          }}
                        />
                      )}
                    </div>
                    {isHovering && (
                      <CustomStyledTooltip
                        title={title}
                        options={options}
                        collapsed={collapsed}
                        coords={parentCoords}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {title !== 'Custom Links' && <CCIcon />}
                    {title === 'Custom Links' && (
                      <img
                        className="w-4"
                        src={options?.icon}
                        onError={(e) => {
                          e.target.onError = null
                          e.target.src = linkIcon
                        }}
                      />
                    )}
                  </>
                )}
                {!collapsed ? (
                  <>
                    {title === 'Custom Links' ? (
                      <div className="flex w-[150px] md:w-[195px] items-center">
                        <li className="text-[12px] pr-[5px] leading-[18px] font-semibold font-primary truncate">
                          {options?.title}
                        </li>
                        <FontAwesomeIcon
                          className="text-[10px]"
                          icon={faArrowUpRightFromSquare}
                        />
                      </div>
                    ) : (
                      <li className="text-[12px] leading-[18px] w-[150px] md:w-[195px] font-semibold font-primary">
                        {options?.title}
                      </li>
                    )}
                  </>
                ) : null}
                {notifications ? (
                  <Pill
                    notifications={notifications}
                    collapsed={collapsed}
                    isActive={isActive}
                  />
                ) : (
                  !collapsed && <div className="w-[22px] h-[16px] "></div>
                )}
              </AnimatedDiv>
            )}
          </NavLink>
          {title === 'Custom Links' && !collapsed && (
            <div className="flex z-50">
              <Icon
                onClick={handleRemoveLink}
                height="20"
                type="regular"
                variant="x"
                className="absolute pt-[10px] left-[208px] text-thryv-black cursor-pointer invisible group-hover:visible"
              />
            </div>
          )}
        </div>
      )
    )
  }

export default MenuItem
