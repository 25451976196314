import {
  Container,
  ConnectedChannelInfo,
  SharedPermissionContainer,
} from '../ManageChannelsModal.styles'

import { useState, useRef, forwardRef, useEffect } from 'react'
import * as yup from 'yup'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  GET_CONNECTED_CHANNELS_BY_USER,
  SET_CHANNEL_NICKNAME,
} from '../../../../../graphql'
import { useMutation } from '@apollo/client'
import { handleConnectChannel } from '../../slices/connectChannelsSlice'
import { useSelector, useDispatch } from 'react-redux'
import formatContactData from '../../../../inbox/hooks/formatContactData'
// Components
import { StatusPill } from '../../../status-pill/status-pill'
import ChannelsIcon from '../../../../channels-icon/channels-icon'
import { ParagraphText, Icon, Button, Input, Tooltip } from '@thryvlabs/maverick'
import { LoadingSpinner } from '../../../loading-spinner'
import { UserPermissionsTable } from './user-permissions-table'
import { useStaffUser } from '../../../../../hooks/use-staff-user'
import { useAuth0 } from '@auth0/auth0-react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import RoundedActionButton from '../../../../inbox/common/rounded-action-button'

const schema = yup.object().shape({
  nickname: yup.string().max(128, 'Nickname cannot be longer than 128 characters'),
})

const SharedPermissionButton = ({ onClick, active }) => {
  return (
    <button
      className="flex justify-center gap-2 items-center py-2 rounded-b"
      onClick={onClick}
    >
      <ParagraphText variant="reg" color="thryv-black-500" className="font-semibold">
        Shared Permissions
      </ParagraphText>
      <Icon
        variant="caretDown"
        type="solid"
        width="10"
        fill="#4D4D4D"
        className={`${active && 'rotate-180'} transition duration-300`}
      />
    </button>
  )
}

const ChannelNameAndInput = forwardRef(
  (
    { email, nickName, provider, register, errors, showNickNameInput, isLoading },
    inputRef,
  ) => {
    const renderNickNameInputForm = () => (
      <div
        className="relative h-[22px] w-[250px] text-start bottom-[3px]"
        ref={inputRef}
      >
        <Input
          className="w-full"
          type="text"
          placeholder="Nickname"
          name="nickname"
          withLabel
          // labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
      </div>
    )

    const renderChannelNameAndEmail = () => (
      <ParagraphText variant="reg" className="truncate sm:max-w-[175px]">
        {nickName.length
          ? `${nickName.replace(/''/g, "'")} \u00A0\u00A0\u00A0 (${email})`
          : email}
      </ParagraphText>
    )

    const renderChannelNameAndPhone = () => (
      <ParagraphText variant="reg" className="truncate sm:max-w-[175px]">
        {nickName.length
          ? `${nickName.replace(/''/g, "'")} \u00A0\u00A0\u00A0 ${email}`
          : email}
      </ParagraphText>
    )

    return (
      <div className="flex items-center max-w-[290px] sm:max-w-[400px]">
        {isLoading ? <LoadingSpinner widthAndHeight={24} /> : null}

        {!isLoading &&
          (showNickNameInput
            ? renderNickNameInputForm()
            : provider === 'phone'
              ? renderChannelNameAndPhone()
              : renderChannelNameAndEmail())}
      </div>
    )
  },
)

const ChannelContainer = ({
  channel,
  channelToDeleteIndex,
  openDeleteModal,
  connectError,
  reauthError,
  staffMembers,
  reconnectButtonStyles,
  disableDelete,
}) => {
  const { role } = useStaffUser()

  const permittedStaff =
    staffMembers.filter((member) =>
      member.permittedChannels.some(
        (currChannel) => currChannel.ChannelID === channel.id,
      ),
    ) || []

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [token, setToken] = useState()

  const { getAccessTokenSilently, user } = useAuth0()

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently()

        setToken(accessToken)
      } catch (err) {
        //
      }
    }

    getToken()
  }, [getAccessTokenSilently])

  const submitNicknameButtonRef = useRef(null)
  const nickNameInputRef = useRef(null)

  const [showPermissions, setShowPermissions] = useState(false)

  const { cardData, channelInfo } = useSelector((state) => state.connectChannels)

  const dispatch = useDispatch()
  const { toggleNylasV3 } = useFlags()

  const [nickNameIsEditing, setNickNameIsEditing] = useState(false)
  const [setChannelNickname, { loading }] = useMutation(SET_CHANNEL_NICKNAME)

  const updateNicknameAsync = async (e) => {
    const regex = /^(?!^\s+$).{1,64}$/ // not comprised entirely of whitespace, 1-64 length
    if (e.nickname === channel.nickname || !regex.test(e.nickname)) {
      setNickNameIsEditing(false)
      return
    }

    try {
      await setChannelNickname({
        variables: {
          channel_id: channel.id,
          nickname: e.nickname,
        },
        refetchQueries: [
          GET_CONNECTED_CHANNELS_BY_USER,
          'queryConnectedChannelsByUser',
        ],
        onCompleted: () => {
          setNickNameIsEditing(false)
        },
      })
    } catch (err) {
      return
    }
  }

  useClickOutside((e) => {
    const submitNicknameButton = submitNicknameButtonRef.current
    const buttonClicked = e.composedPath().includes(submitNicknameButton)
    if (!buttonClicked) setNickNameIsEditing(false)
  }, nickNameInputRef)

  useEffect(() => {
    if (connectError && showPermissions) {
      setShowPermissions(false)
    }
  }, [connectError])

  const [loadingReconnection, setLoadingReconnection] = useState(false)

  const handleReconnectChannel = () => {
    let ccuid = user.cc_uid
    // alert('running reconnect')
    setLoadingReconnection(true)
    const channelIndex = channelToDeleteIndex
    const clickedID = cardData.findIndex(
      (item) =>
        item.ButtonText.toLowerCase() ===
        channelInfo[channelIndex]?.Provider.toLowerCase(),
    )
    setTimeout(() => {
      setLoadingReconnection(false)
      alert('error reconnecting please try again')
    }, 8000)

    //need to add timer to remove loading animation and return error if the reconnection fails
    dispatch(handleConnectChannel({ clickedID, token, toggleNylasV3, ccuid }))
  }

  const handleStatusPillText = () => {
    if (connectError) {
      return 'Disconnected'
    }

    if (reauthError) {
      return 'Action needed'
    }

    return 'Connected'
  }

  return (
    <Container
      className={`border ${
        connectError || reauthError
          ? ' border-[1.5px] border-[#DF2A2A]'
          : 'border-thryv-white-300'
      }`}
    >
      <ConnectedChannelInfo>
        <form
          className="flex sm:flex-col w-full justify-between"
          onSubmit={handleSubmit(updateNicknameAsync)}
        >
          <div className="flex items-center justify-between sm:justify-start sm:mb-2 gap-4">
            <ChannelsIcon variant={channel.iconName} width={34} height={34} />
            <div className="flex gap-2.5">
              {channel.nickname.length > 30 ? (
                <Tooltip variant="top" title={channel.email}>
                  <ChannelNameAndInput
                    ref={nickNameInputRef}
                    nickName={channel.nickname}
                    provider={channel.provider}
                    email={formatContactData(channel.email)}
                    showNickNameInput={nickNameIsEditing}
                    register={register}
                    errors={errors}
                    isLoading={loading}
                  />
                </Tooltip>
              ) : (
                <ChannelNameAndInput
                  ref={nickNameInputRef}
                  nickName={channel.nickname}
                  provider={channel.provider}
                  email={formatContactData(channel.email)}
                  showNickNameInput={nickNameIsEditing}
                  register={register}
                  errors={errors}
                  isLoading={loading}
                />
              )}

              <StatusPill
                className="h-[22px] flex items-center justify-center py-auto sm:absolute sm:bottom-3 sm:left-3"
                color={connectError || reauthError ? '#DF2A2A' : '#16A085'}
              >
                <ParagraphText variant="sm" className="leading-[18px]" color="white">
                  {handleStatusPillText()}
                </ParagraphText>
              </StatusPill>
            </div>
          </div>

          {role !== 'staff' ? (
            <div className="flex items-center gap-4 sm:justify-end sm:gap-3">
              <div className="h-[30px] w-[30px]">
                {/* using the wrong action Button */}
                <RoundedActionButton
                  className={`
                  absolute sm:-left-[10px] !w-[30px] !h-[30px]
                  ${nickNameIsEditing ? 'hidden' : 'block'}
                `}
                  dataTestId="edit-channel-btn"
                  icon={{
                    variant: 'pen',
                    type: 'regular',
                    width: '18',
                    height: '18',
                    color: '#808080',
                    hoverColor: '#4D4D4D',
                  }}
                  isMavIcon
                  onClick={() => setNickNameIsEditing(true)}
                />
                <RoundedActionButton
                  ref={submitNicknameButtonRef}
                  className={`
                  absolute sm:-left-[10px] !w-[30px] !h-[30px]
                  ${nickNameIsEditing ? 'block' : 'hidden'}
                  ${loading ? 'pointer-events-none' : ''}
                `}
                  type="submit"
                  icon={{
                    variant: 'check',
                    type: 'regular',
                    width: '18',
                    height: '13',
                    color: '#057AFF',
                    hoverColor: '#057AFF',
                  }}
                  isMavIcon
                />
              </div>

              <RoundedActionButton
                disable={disableDelete}
                className="!w-[30px] !h-[30px] sm:absolute sm:-left-3 sm:-top-3.5"
                dataTestId={`delete-channel-btn-${channelToDeleteIndex}`}
                icon={{
                  variant: 'altTrash',
                  type: 'regular',
                  width: '16',
                  height: '18',
                  color: '#808080',
                  hoverColor: '#4D4D4D',
                }}
                isMavIcon
                tooltipTitle="Delete"
                onClick={() => openDeleteModal(channelToDeleteIndex)}
              />
            </div>
          ) : null}
        </form>
      </ConnectedChannelInfo>

      <SharedPermissionContainer className="bg-thryv-white-200 border-t border-thryv-white-300 no-scrollbar">
        {connectError || reauthError ? (
          <div className="w-full py-2">
            {!loadingReconnection ? (
              <Button
                variant="text"
                level={1}
                className={reconnectButtonStyles}
                onClick={() => handleReconnectChannel()}
              >
                {connectError ? 'Reconnect' : 'Reauthenticate'}
              </Button>
            ) : (
              <LoadingSpinner widthAndHeight={36} />
            )}
          </div>
        ) : null}

        {!connectError || !reauthError ? (
          <SharedPermissionButton
            onClick={() => setShowPermissions(!showPermissions)}
            active={showPermissions}
          />
        ) : null}

        <div className="px-4">
          <UserPermissionsTable
            channel={channel}
            show={showPermissions}
            staffMembers={permittedStaff}
          />
        </div>
      </SharedPermissionContainer>
    </Container>
  )
}

export default ChannelContainer
