import { useQuery } from '@apollo/client'
import { Button, Header, Icon } from '@thryvlabs/maverick'
import { GET_STAFF } from '../../../../graphql'
import {
  setDisableBackdropClick,
  setModalContent,
  setShowModal,
} from '../slices/connectChannelsSlice'
import { useDispatch } from 'react-redux'

const FreemiumStaffUpdate = ({ handleCloseStaffChangeModal }) => {
  const { data: staffData } = useQuery(GET_STAFF)
  const staffCount = staffData?.staff?.length
  const dispatch = useDispatch()

  const openUpgradePlanModal = () => {
    sessionStorage.setItem('seenStaffUpdateModal', true)
    dispatch(setModalContent(4))
    dispatch(setDisableBackdropClick(false))
    dispatch(setShowModal(true))
  }

  return (
    <div className="w-[436px] p-2 text-left">
      <div className="flex justify-between items-center gap-8 w-full">
        <Header variant="h4" fontWeight="semibold">
          Upcoming Changes to Your Command Center Plan
        </Header>
        <Button variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={handleCloseStaffChangeModal}
          />
        </Button>
      </div>

      {staffCount <= 3 && (
        <div className="font-open-sans text-sm text-thryv-black py-5">
          <p className="pb-3">
            Starting Wednesday, October 2nd, 2024 the{' '}
            <b className="text-[#4761C3]">Free Forever</b> plan will now offer 3 free
            users seats instead of 5.
          </p>
          <p className="pb-3">
            To keep or maintain your current user count, upgrade to our{' '}
            <b className="text-[#4761C3]">Plus</b> or{' '}
            <b className="text-[#4761C3]">Professional</b> plans. Or, you can reduce
            the number of registered users to 3.
          </p>
          <p>
            Don’t miss out — upgrade today and continue enjoying all the benefits!
          </p>
        </div>
      )}

      {staffCount > 3 && (
        <div className="py-5">
          <p className="font-montserrat text-lg font-semibold pb-3">
            1. What’s Changing?
            <div className="font-open-sans text-sm font-normal text-thryv-black py-5">
              <p className="pb-3">
                Starting Wednesday, October 2nd, 2024 the{' '}
                <b className="text-[#4761C3]">Free Forever</b> plan will now offer 3
                free users seats instead of 5.
              </p>
              <p className="pb-3">
                You can continue using your current number of users seats for one
                year. After October 2nd, 2025 to keep or maintain your current user
                count, you will need to upgrade to our{' '}
                <b className="text-[#4761C3]">Plus</b> or{' '}
                <b className="text-[#4761C3]">Professional</b> plans. Or, you can
                reduce the number of registered users to 3.
              </p>
              <p>
                Don’t miss out — upgrade before October 2nd, 2025 and continue
                enjoying all the benefits!
              </p>
            </div>
          </p>
          <p className="font-montserrat text-lg font-semibold pb-3">
            2. What You Can Do
          </p>
          <p className="font-open-sans text-sm font-normal text-thryv-black">
            Upgrade your plan before October 2nd, 2025 to ensure your registered
            users can continue using Command Center services seamlessly. Please use
            the button below to upgrade your plan. If you have any questions or
            concerns about these updates, our support team is here to help. You can
            reach us at{' '}
            <a
              href="support@thryv.com"
              className="text-[#057AFF] underline-offset-1"
            >
              support@thryv.com
            </a>
          </p>
        </div>
      )}

      <div className="flex justify-center">
        <Button
          variant="primary"
          className="rounded-full"
          onClick={openUpgradePlanModal}
        >
          UPGRADE NOW
        </Button>
      </div>
    </div>
  )
}

export default FreemiumStaffUpdate
