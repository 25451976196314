import { useEffect, useState } from 'react'
import Voicemail from './Voicemail'
import DefaultScreen from '../../recent-and-voicemail-components/default-screens/DefaultScreenMain'
import { determineShowDefaultScreen } from '../../helpers/recentAndVoicemailHelpers'
import { useSelector } from 'react-redux'
import { PaginatedList } from '@thryvlabs/maverick'

const Voicemails = ({ setDisplayInfoCard, isMobile, setContactInfoView }) => {
  const [callsCheckable, setCallsCheckable] = useState(false)
  const [checked, setChecked] = useState([])
  const voicemails = useSelector((state) => state.calls.voicemails)
  const tabSelected = 'voicemail'
  const [voicemailsData, setVoicemailsData] = useState([])

  const hasVoiceMail = voicemails?.length > 0

  useEffect(() => {
    let refactoredVoicemails = voicemails.filter((data) => {
      return data
    })
    setVoicemailsData([...refactoredVoicemails])
  }, [voicemails])

  return (
    <>
      {determineShowDefaultScreen(tabSelected, voicemails) ? (
        <DefaultScreen tabSelected={tabSelected} isMobile={isMobile} />
      ) : (
        <>
          <div className="h-full flex flex-col min-w-md:pt-[16px] p-[20px] md:p-[0px] md:bg-white font-montserrat">
            {hasVoiceMail && voicemailsData ? (
              <PaginatedList list={voicemailsData}>
                <Voicemail
                  setContactInfoView={setContactInfoView}
                  isMobile={isMobile}
                  callsCheckable={callsCheckable}
                  setCallsCheckable={setCallsCheckable}
                  setDisplayInfoCard={setDisplayInfoCard}
                  checked={checked}
                  setChecked={setChecked}
                />
              </PaginatedList>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Voicemails
